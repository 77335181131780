import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { NextButtonDisabledPipe } from '@app/shared/pipes/next-button-disabled.pipe';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SharedModule } from '@app/modules/shared/shared.module';
import { TranslatePipe } from '@ngx-translate/core';
import { filter, take } from 'rxjs';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RequiredSuffixDirective } from '@app/shared/directives/required-suffix.directive';
import {
  CONTROL_TYPE,
  ControllableDetailsDto,
  CONTROLLER,
  EXISTING_SYSTEM,
  NETWORK_AND_SYSTEM_PROTECTION,
  NETWORK_FEE_MODULE,
  NetworkAndSystemProtectionDetailsDto,
} from '@app/models/registration-form';
import { CustomValidators } from '@app/shared/validators/custom-validators';

@Component({
  selector: 'app-storage-controllable-system',
  standalone: true,
  imports: [
    MatCheckbox,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatRadioButton,
    MatRadioGroup,
    NextButtonDisabledPipe,
    ReactiveFormsModule,
    SharedModule,
    TranslatePipe,
    RequiredSuffixDirective,
  ],
  templateUrl: './storage-controllable-system.component.html',
})
export class StorageControllableSystemComponent implements OnInit {
  readonly #appStateService: AppStateService = inject(AppStateService);
  readonly #formBuilder: FormBuilder = inject(FormBuilder);
  readonly #routeService: RouteService = inject(RouteService);
  readonly #destroyRef = inject(DestroyRef);

  public form!: FormGroup;
  public allTouched = false;
  public readonly CONTROLLER_COMPETITIVE_METERING_POINT_OPERATOR =
    CONTROLLER.COMPETITIVE_METERING_POINT_OPERATOR;
  public readonly CONTROLLER = Object.values(CONTROLLER);
  public readonly NETWORK_AND_SYSTEM_PROTECTION_METER_LOCATION =
    NETWORK_AND_SYSTEM_PROTECTION.METER_LOCATION;
  public readonly NETWORK_AND_SYSTEM_PROTECTION = Object.values(
    NETWORK_AND_SYSTEM_PROTECTION
  );
  public readonly EXISTING_SYSTEM = Object.values(EXISTING_SYSTEM);
  public readonly CONTROL_TYPE = Object.values(CONTROL_TYPE);
  public readonly NETWORK_FEE_MODULE = Object.values(NETWORK_FEE_MODULE);

  constructor() {}

  public ngOnInit(): void {
    this.createForm();
    this.updateForm();
    this.watchForm();
  }

  private createForm(): void {
    // ControllableDetailsDto
    this.form = this.#formBuilder.group({
      totalMinimumPower: [null, Validators.required],
      compliant: [null, Validators.required],
      controller: [null, Validators.required],
      networkAndSystemProtection_location: [null, Validators.required],
      networkAndSystemProtection_manufacturer: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      networkAndSystemProtection_type: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      existingSystem: [null, Validators.required],
      controlType: [null, Validators.required],
      networkFeeModule: [null, Validators.required],
      controllerName: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
    });
  }

  private updateForm(): void {
    this.#appStateService
      .observeState()
      .pipe(filter(Boolean), take(1), takeUntilDestroyed(this.#destroyRef))
      .subscribe(form => {
        if (form.formData.storageControllableDetails) {
          this.form.patchValue(form.formData.storageControllableDetails);
        }
        this.form
          .get('networkAndSystemProtection_location')
          ?.setValue(
            form.formData.storageNetworkAndSystemProtectionDetails?.location
          );
        this.form
          .get('networkAndSystemProtection_manufacturer')
          ?.setValue(
            form.formData.storageNetworkAndSystemProtectionDetails?.manufacturer
          );
        this.form
          .get('networkAndSystemProtection_type')
          ?.setValue(
            form.formData.storageNetworkAndSystemProtectionDetails?.type
          );
        this.configureControllerInput();
        this.configureNetworkAndSystemProtectionInputs();
      });
  }

  private watchForm(): void {
    this.form
      .get('networkAndSystemProtection_location')
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.configureNetworkAndSystemProtectionInputs());

    this.form
      .get('controller')
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.configureControllerInput());
  }

  private configureControllerInput() {
    if (
      this.form.get('controller')?.value ===
      CONTROLLER.COMPETITIVE_METERING_POINT_OPERATOR
    ) {
      this.form.get('controllerName')?.enable();
    } else {
      this.form.get('controllerName')?.disable();
    }
  }
  private configureNetworkAndSystemProtectionInputs() {
    if (
      this.form.get('networkAndSystemProtection_location')?.value ===
      NETWORK_AND_SYSTEM_PROTECTION.METER_LOCATION
    ) {
      this.form.get('networkAndSystemProtection_manufacturer')?.enable();
      this.form.get('networkAndSystemProtection_type')?.enable();
    } else {
      this.form.get('networkAndSystemProtection_manufacturer')?.disable();
      this.form.get('networkAndSystemProtection_type')?.disable();
    }
  }

  public previous(): void {
    this.updateState();
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.form.valid) {
      this.updateState();
      this.#routeService.navigateToNextStep();
    } else {
      this.form.markAllAsTouched();
      this.allTouched = true;
    }
  }

  private updateState() {
    const storageControllableDetails: ControllableDetailsDto = this.form.value;
    const storageNetworkAndSystemProtectionDetails: NetworkAndSystemProtectionDetailsDto =
      {
        location: this.form.get('networkAndSystemProtection_location')?.value,
        manufacturer: this.form.get('networkAndSystemProtection_manufacturer')
          ?.value,
        type: this.form.get('networkAndSystemProtection_type')?.value,
      };
    this.#appStateService.updateFormData({
      storageControllableDetails: storageControllableDetails,
      storageNetworkAndSystemProtectionDetails:
        storageNetworkAndSystemProtectionDetails,
    });
  }
}
