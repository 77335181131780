import { Component, Input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ROUTES } from '@app/models/routes.enum';
import {
  ControllableDetailsDto,
  CONTROLLER,
  NETWORK_AND_SYSTEM_PROTECTION_TYPE,
  NetworkAndSystemProtectionDetailsDto,
} from '@app/models/registration-form';
import { DatePipe } from '@angular/common';
import { SharedModule } from '@app/modules/shared/shared.module';

@Component({
  selector: 'app-storage-controllable-system-summary',
  standalone: true,
  imports: [DatePipe, SharedModule, TranslatePipe],
  templateUrl: './storage-controllable-system-summary.component.html',
})
export class StorageControllableSystemSummaryComponent {
  @Input({ required: true })
  public controllableDetails!: ControllableDetailsDto;
  @Input({ required: false })
  public networkAndSystemProtectionDetails?: NetworkAndSystemProtectionDetailsDto;
  public route = ROUTES.STORAGE_CONTROLLABLE_SYSTEM;
  public CONTROLLER = CONTROLLER;
  public NETWORK_AND_SYSTEM_PROTECTION_TYPE =
    NETWORK_AND_SYSTEM_PROTECTION_TYPE;
}
