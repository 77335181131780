import { Injectable } from '@angular/core';
import {
  DocumentDetails,
  commissioningElectricityDocumentTypeMapping,
  constructionWaterDocumentTypeMapping,
  electricityDocumentTypeMapping,
  gasDocumentTypeMapping,
  heatDocumentTypeMapping,
  sewageDocumentTypeMapping,
  waterDocumentTypeMapping,
} from '@app/constants/document-mappings';
import {
  COMMISSIONING_FLOW_TYPE,
  ELECTRICITY_SYSTEM_TYPE,
} from '@app/models/commissioning-mapped-data.interface';
import {
  FLOW,
  FLOW_MODULE,
  FormDataObject,
} from '@app/models/form-data.interface';
import {
  FORM_TYPE,
  DOCUMENT_TYPE,
  WATER_OPTION,
  SEWAGE_OPTION,
  GAS_OPTION,
  GRID_CONNECTION_TYPE,
  PV_TYPE,
  HEAT_OPTION,
} from '@app/models/registration-form';

@Injectable({
  providedIn: 'root',
})
export class DocumentMapperService {
  private availableDocumentTypes!: DocumentDetails[];

  public getAvailableDocumentTypes(
    formData: FormDataObject
  ): DocumentDetails[] {
    if (formData.selectedFlow === FLOW.COMMISSIONING) {
      return this.evaluateCommissioningDocumentTypes(formData);
    }

    if (formData.selectedFlow !== FLOW.REGISTRATION) {
      return [];
    }

    switch (formData.selectedModule) {
      case FLOW_MODULE.WATER:
        return this.evaluateWaterModuleDocumentTypes(formData);
      case FLOW_MODULE.GAS:
        return this.evaluateAvailableGasDocumentTypes(
          formData.gasOptions!.gasOption
        );
      case FLOW_MODULE.ELECTRICITY:
        return this.evaluateAvailableElectricityDocumentTypes(formData);

      case FLOW_MODULE.HEAT:
        return this.evaluateAvailableHeatDocumentTypes(
          formData.heatOptions!.heatOption
        );

      default:
        return [];
    }
  }

  private evaluateWaterModuleDocumentTypes(
    formData: FormDataObject
  ): DocumentDetails[] {
    switch (formData.waterArea) {
      case FORM_TYPE.SEWAGE:
        return this.evaluateAvailableSewageDocumentTypes(
          formData.sewageOptions!.sewageOption
        );
      case FORM_TYPE.CONSTRUCTION_WATER:
        return this.evaluateAvailableConstructionWaterDocumentTypes();
      case FORM_TYPE.WATER:
        return this.evaluateAvailableWaterDocumentTypes(
          formData.waterOptions!.waterOption
        );
      default:
        return [];
    }
  }

  private evaluateCommissioningDocumentTypes({
    commissioningFlowType,
    commissioningElectricitySystems,
  }: FormDataObject): DocumentDetails[] {
    this.availableDocumentTypes = commissioningFlowType
      ? (commissioningElectricityDocumentTypeMapping.get(
          commissioningFlowType
        ) ?? [])
      : [];
    // if PV/STORAGE not selected in electricity systems:
    // remove COMMISSIONING_PROTOCOL
    if (
      commissioningFlowType === COMMISSIONING_FLOW_TYPE.CUSTOMER_FACILITY &&
      commissioningElectricitySystems &&
      !commissioningElectricitySystems[ELECTRICITY_SYSTEM_TYPE.PV].selected &&
      !commissioningElectricitySystems[ELECTRICITY_SYSTEM_TYPE.STORAGE].selected
    ) {
      this.availableDocumentTypes = this.availableDocumentTypes.filter(
        ({ typeName }) => typeName !== DOCUMENT_TYPE.COMMISSIONING_PROTOCOL
      );
    }
    return this.availableDocumentTypes;
  }

  private evaluateAvailableSewageDocumentTypes(
    currentSewageFlow: SEWAGE_OPTION
  ): DocumentDetails[] {
    this.availableDocumentTypes =
      sewageDocumentTypeMapping.get(currentSewageFlow) ?? [];
    return this.availableDocumentTypes;
  }

  private evaluateAvailableConstructionWaterDocumentTypes(): DocumentDetails[] {
    return (
      constructionWaterDocumentTypeMapping.get(FORM_TYPE.CONSTRUCTION_WATER) ??
      []
    );
  }

  private evaluateAvailableWaterDocumentTypes(
    currentWaterFlow: WATER_OPTION
  ): DocumentDetails[] {
    return waterDocumentTypeMapping.get(currentWaterFlow) ?? [];
  }

  private evaluateAvailableGasDocumentTypes(
    currentGasFlow: GAS_OPTION
  ): DocumentDetails[] {
    return gasDocumentTypeMapping.get(currentGasFlow) ?? [];
  }

  private evaluateAvailableElectricityDocumentTypes(
    formData: FormDataObject
  ): DocumentDetails[] {
    const currentFlow = this.getCurrentElectricityFlow(formData);
    this.availableDocumentTypes =
      electricityDocumentTypeMapping.get(currentFlow) ?? [];

    // if devicesRequireApproval is not selected in CONSTRUCTION_ELECTRICITY flow:
    // remove DATA_SHEET_DEVICES_REQUIRING_APPROVAL document
    if (
      formData.formType === FORM_TYPE.CONSTRUCTION_ELECTRICITY &&
      !formData.constructionElectricity?.devicesRequireApproval
    ) {
      this.availableDocumentTypes = this.availableDocumentTypes.filter(
        ({ typeName }) =>
          typeName !== DOCUMENT_TYPE.DATA_SHEET_DEVICES_REQUIRING_APPROVAL
      );
    }
    // if storage is not selected in PV new flow:
    // remove DATA_SHEET_STORAGE, UNIT_CERTIFICATE_GENERATION_PLANT and UNIT_CERTIFICATE_STORAGE
    // add UNIT_CERTIFICATE
    if (
      formData.formType === FORM_TYPE.PV &&
      formData.pvType === PV_TYPE.NEW &&
      !formData.pvFacilityInformation?.storagePlanned
    ) {
      this.availableDocumentTypes = this.availableDocumentTypes.filter(
        ({ typeName }) =>
          ![
            DOCUMENT_TYPE.DATA_SHEET_STORAGE,
            DOCUMENT_TYPE.UNIT_CERTIFICATE_GENERATION_PLANT,
            DOCUMENT_TYPE.UNIT_CERTIFICATE_STORAGE,
          ].includes(typeName)
      );
      this.availableDocumentTypes.splice(3, 0, {
        typeName: DOCUMENT_TYPE.UNIT_CERTIFICATE,
        description: DOCUMENT_TYPE.UNIT_CERTIFICATE,
        required: true,
      });
    }
    return this.availableDocumentTypes;
  }

  private getCurrentElectricityFlow(
    formData: FormDataObject
  ): FORM_TYPE | GRID_CONNECTION_TYPE | `PV_${PV_TYPE}` {
    if (
      formData.formType === FORM_TYPE.GRID_CONNECTION &&
      formData.gridConnectionType
    ) {
      return formData.gridConnectionType;
    } else if (formData.formType === FORM_TYPE.PV && formData.pvType) {
      return `PV_${formData.pvType}`;
    } else {
      return formData.formType!;
    }
  }

  private evaluateAvailableHeatDocumentTypes(
    currentHeatFlow: HEAT_OPTION
  ): DocumentDetails[] {
    return heatDocumentTypeMapping.get(currentHeatFlow) ?? [];
  }
}
