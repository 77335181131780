import { COMMISSIONING_FLOW_TYPE } from '@app/models/commissioning-mapped-data.interface';
import {
  DOCUMENT_TYPE,
  FORM_TYPE,
  GAS_OPTION,
  GRID_CONNECTION_TYPE,
  HEAT_OPTION,
  PV_TYPE,
  SEWAGE_OPTION,
  WATER_OPTION,
} from '@app/models/registration-form';

export interface DocumentDetails {
  typeName: DOCUMENT_TYPE;
  description?: string;
  required?: boolean;
  downloadLink?: string;
}

export const waterDocumentTypeMapping = new Map<
  WATER_OPTION,
  DocumentDetails[]
>([
  [
    WATER_OPTION.NEW,
    [
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.FLOOR_PLAN,
        description: DOCUMENT_TYPE.FLOOR_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_WATER,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_WATER,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
  [
    WATER_OPTION.CHANGE,
    [
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.FLOOR_PLAN,
        description: DOCUMENT_TYPE.FLOOR_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_WATER,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_WATER,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
  [
    WATER_OPTION.DETACH,
    [
      {
        typeName: DOCUMENT_TYPE.PROOF_OF_OWNERSHIP,
        description: DOCUMENT_TYPE.PROOF_OF_OWNERSHIP,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN + '_WATER_DETACH',
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_WATER,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_WATER,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
]);

export const sewageDocumentTypeMapping = new Map<
  SEWAGE_OPTION,
  DocumentDetails[]
>([
  [
    SEWAGE_OPTION.NEW,
    [
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.FLOOR_PLAN,
        description: DOCUMENT_TYPE.FLOOR_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_WATER,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_WATER,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
  [
    SEWAGE_OPTION.DETACH,
    [
      {
        typeName: DOCUMENT_TYPE.PROOF_OF_OWNERSHIP,
        description: DOCUMENT_TYPE.PROOF_OF_OWNERSHIP,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN + '_WATER_DETACH',
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_WATER,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_WATER,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
]);

export const constructionWaterDocumentTypeMapping = new Map<
  FORM_TYPE.CONSTRUCTION_WATER,
  DocumentDetails[]
>([
  [
    FORM_TYPE.CONSTRUCTION_WATER,
    [
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
]);

export const gasDocumentTypeMapping = new Map<GAS_OPTION, DocumentDetails[]>([
  [
    GAS_OPTION.NEW,
    [
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.FLOOR_PLAN,
        description: DOCUMENT_TYPE.FLOOR_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
  [
    GAS_OPTION.CHANGE,
    [
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
  [
    GAS_OPTION.DETACH,
    [
      {
        typeName: DOCUMENT_TYPE.PROOF_OF_OWNERSHIP,
        description: DOCUMENT_TYPE.PROOF_OF_OWNERSHIP,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
]);

export const electricityDocumentTypeMapping = new Map<
  string,
  DocumentDetails[]
>([
  [
    FORM_TYPE.CHARGING_DEVICE,
    [
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: `${DOCUMENT_TYPE.SITE_PLAN}_CHARGING_DEVICE`,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.CONSUMER_LINEUP,
        description: DOCUMENT_TYPE.CONSUMER_LINEUP,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
  [
    `PV_${PV_TYPE.NEW}`,
    [
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: `${DOCUMENT_TYPE.SITE_PLAN}_PV`,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.DATA_SHEET_MANUFACTURER,
        description: `${DOCUMENT_TYPE.DATA_SHEET_MANUFACTURER}_PV`,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.DATA_SHEET_GENERATION_PLANTS,
        description: DOCUMENT_TYPE.DATA_SHEET_GENERATION_PLANTS,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.DATA_SHEET_STORAGE,
        description: DOCUMENT_TYPE.DATA_SHEET_STORAGE,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.UNIT_CERTIFICATE_GENERATION_PLANT,
        description: DOCUMENT_TYPE.UNIT_CERTIFICATE_GENERATION_PLANT,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.UNIT_CERTIFICATE_STORAGE,
        description: DOCUMENT_TYPE.UNIT_CERTIFICATE_STORAGE,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.GRID_SYSTEM_PROTECTION_CERTIFICATE,
        description: DOCUMENT_TYPE.GRID_SYSTEM_PROTECTION_CERTIFICATE,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.MEASUREMENT_CONCEPT,
        description: DOCUMENT_TYPE.MEASUREMENT_CONCEPT,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.WIRING_DIAGRAM,
        description: DOCUMENT_TYPE.WIRING_DIAGRAM,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
        required: false,
      },
    ],
  ],
  [
    `PV_${PV_TYPE.CHANGE}`,
    [
      {
        typeName: DOCUMENT_TYPE.MEASUREMENT_CONCEPT,
        description: DOCUMENT_TYPE.MEASUREMENT_CONCEPT,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.FINAL_REPORT,
        description: DOCUMENT_TYPE.FINAL_REPORT,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
  [
    FORM_TYPE.BALCONY_PV,
    [
      {
        typeName: DOCUMENT_TYPE.DATA_SHEET_MANUFACTURER,
        description: DOCUMENT_TYPE.DATA_SHEET_MANUFACTURER + '_BALCONY_PV',
        required: false,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
      },
    ],
  ],
  [
    GRID_CONNECTION_TYPE.NEW,
    [
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.FLOOR_PLAN,
        description: DOCUMENT_TYPE.FLOOR_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
  [
    GRID_CONNECTION_TYPE.POWER,
    [
      {
        typeName: DOCUMENT_TYPE.CONSUMER_LINEUP,
        description: DOCUMENT_TYPE.CONSUMER_LINEUP,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
  [
    GRID_CONNECTION_TYPE.CHANGE,
    [
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.FLOOR_PLAN,
        description: DOCUMENT_TYPE.FLOOR_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
  [
    GRID_CONNECTION_TYPE.DETACH,
    [
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: `${DOCUMENT_TYPE.OTHER}_GRID_CONNECTION_DETACH`,
      },
    ],
  ],
  [
    FORM_TYPE.HEAT_PUMP,
    [
      {
        typeName: DOCUMENT_TYPE.DATA_SHEET_MANUFACTURER,
        description: DOCUMENT_TYPE.DATA_SHEET_MANUFACTURER,
      },
      {
        typeName: DOCUMENT_TYPE.CONSUMER_LINEUP,
        description: DOCUMENT_TYPE.CONSUMER_LINEUP,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
  [
    FORM_TYPE.STORAGE_2,
    [
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN + '_' + FORM_TYPE.STORAGE_2,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.MEASUREMENT_CONCEPT,
        description:
          DOCUMENT_TYPE.MEASUREMENT_CONCEPT + '_' + FORM_TYPE.STORAGE_2,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.UNIT_CERTIFICATE,
        description: DOCUMENT_TYPE.UNIT_CERTIFICATE,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
  [
    FORM_TYPE.CONSTRUCTION_ELECTRICITY,
    [
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.DATA_SHEET_DEVICES_REQUIRING_APPROVAL,
        description: DOCUMENT_TYPE.DATA_SHEET_DEVICES_REQUIRING_APPROVAL,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
  [
    FORM_TYPE.OTHER,
    [
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
]);

export const heatDocumentTypeMapping = new Map<HEAT_OPTION, DocumentDetails[]>([
  [
    HEAT_OPTION.NEW,
    [
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.FLOOR_PLAN,
        description: DOCUMENT_TYPE.FLOOR_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
  [
    HEAT_OPTION.CHANGE,
    [
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.FLOOR_PLAN,
        description: DOCUMENT_TYPE.FLOOR_PLAN,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
  [
    HEAT_OPTION.DETACH,
    [
      {
        typeName: DOCUMENT_TYPE.PROOF_OF_OWNERSHIP,
        description: DOCUMENT_TYPE.PROOF_OF_OWNERSHIP,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN + '_HEAT_DETACH',
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
]);

export const commissioningElectricityDocumentTypeMapping = new Map<
  COMMISSIONING_FLOW_TYPE,
  DocumentDetails[]
>([
  [
    COMMISSIONING_FLOW_TYPE.MAIN_POWER_SUPPLY,
    [
      {
        typeName: DOCUMENT_TYPE.SITE_PLAN,
        description: DOCUMENT_TYPE.SITE_PLAN,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER + '_COMMISSIONING_ELECTRICITY',
      },
    ],
  ],
  [
    COMMISSIONING_FLOW_TYPE.CUSTOMER_FACILITY,
    [
      {
        typeName: DOCUMENT_TYPE.COMMISSIONING_PROTOCOL,
        description: DOCUMENT_TYPE.COMMISSIONING_PROTOCOL,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
        description: DOCUMENT_TYPE.INSTALLER_LICENCE_ELECTRICITY,
        required: true,
      },
      {
        typeName: DOCUMENT_TYPE.OVERVIEW_CIRCUIT_DIAGRAM,
        description: DOCUMENT_TYPE.OVERVIEW_CIRCUIT_DIAGRAM,
      },
      {
        typeName: DOCUMENT_TYPE.COMMISSIONING_PICTURES,
        description: DOCUMENT_TYPE.COMMISSIONING_PICTURES,
      },
      {
        typeName: DOCUMENT_TYPE.OTHER,
        description: DOCUMENT_TYPE.OTHER,
      },
    ],
  ],
]);
