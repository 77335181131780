import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FLOW_MODULE } from '@app/models/form-data.interface';
import { FORM_TYPE } from '@app/models/registration-form';
import {
  DialogComponent,
  DialogData,
} from '@app/modules/shared/dialog/dialog.component';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { filter, Observable, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.component.html',
})
export class ProductSelectionComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject();
  public productTypeControl!: FormControl;
  public formType = FORM_TYPE;
  public productDetailsList: {
    type: FORM_TYPE;
    icon?: string;
    svgIcon?: string;
  }[] = [
    {
      type: FORM_TYPE.PV,
      icon: 'solar_power',
    },
    {
      type: FORM_TYPE.STORAGE_2,
      svgIcon: 'electricity_storage',
    },
    {
      type: FORM_TYPE.HEAT_PUMP,
      svgIcon: 'heat_pump',
    },
    {
      type: FORM_TYPE.GRID_CONNECTION,
      icon: 'power',
    },
    {
      type: FORM_TYPE.CHARGING_DEVICE,
      icon: 'electric_car',
    },
    {
      type: FORM_TYPE.CONSTRUCTION_ELECTRICITY,
      svgIcon: 'construction_electricity',
    },
    {
      type: FORM_TYPE.BALCONY_PV,
      svgIcon: 'solar_power_balcony',
    },
    {
      type: FORM_TYPE.OTHER,
      icon: 'more_horiz',
    },
  ];
  public selectedProduct?: FORM_TYPE | null;
  public productSelectionVisited = false;
  public allTouched = false;
  public electricityModule = FLOW_MODULE.ELECTRICITY;

  constructor(
    private appStateService: AppStateService,
    private routeService: RouteService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.createProductForm();
    this.updateForm();
    this.watchForm();
  }

  private createProductForm(): void {
    this.productTypeControl = this.formBuilder.control(
      null,
      Validators.required
    );
  }

  private updateForm(): void {
    this.appStateService
      .observeState()
      .pipe(filter(Boolean), take(1), takeUntil(this.onDestroy$))
      .subscribe(({ formData, otherData }) => {
        if (formData.formType) {
          this.productTypeControl.patchValue(formData.formType);
          this.selectedProduct = formData.formType;
        }
        if (otherData.productSelectionVisited) {
          this.productSelectionVisited = otherData.productSelectionVisited;
        }
      });
  }

  private watchForm(): void {
    this.productTypeControl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(selectedProduct =>
        this.appStateService.updateFormData({
          formType: selectedProduct,
        })
      );
  }

  public selectProduct(selectedProduct: FORM_TYPE): void {
    const productChangeAfterFormFilled =
      this.selectedProduct &&
      this.productSelectionVisited &&
      this.selectedProduct !== selectedProduct;

    if (productChangeAfterFormFilled) {
      this.openProductChangeDialog().subscribe({
        next: result => {
          if (result) {
            this.appStateService.clearState(false, false);
            this.changeProduct(selectedProduct);
          }
        },
      });
    } else {
      this.changeProduct(selectedProduct);
    }
  }

  private changeProduct(selectedProductType: FORM_TYPE): void {
    this.productTypeControl.setValue(selectedProductType);
    this.selectedProduct = selectedProductType;
  }

  public previous(): void {
    this.routeService.navigateToPreviousStep();
  }

  public next(): void {
    this.allTouched = true;
    this.productTypeControl.markAllAsTouched();
    if (this.productTypeControl.valid) {
      this.appStateService.updateOtherData({
        productSelectionVisited: true,
      });
      this.routeService.navigateToNextStep();
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public openProductChangeDialog(): Observable<boolean> {
    const dialogData: DialogData = {
      heading: 'PRODUCTS.CHANGE_DIALOG.HEADING',
      message: 'PRODUCTS.CHANGE_DIALOG.MESSAGE',
      acceptLabel: 'COMMON.YES',
      rejectLabel: 'COMMON.NO',
    };
    const dialogRef = this.dialog.open(DialogComponent, {
      data: dialogData,
      maxWidth: 400,
      autoFocus: 'dialog',
      hasBackdrop: true,
    });

    return dialogRef.afterClosed().pipe(take(1));
  }
}
