<app-form-card
  titleIcon="3p"
  cardTitle="SUMMARY.HEADING"
  dataTestId="summary-heading">
  <p class="description">
    {{ 'SUMMARY.DESCRIPTION' | translate }}
  </p>
  @if (loading()) {
    <app-loading-spinner></app-loading-spinner>
  }

  <div>
    @if ((state$ | async)?.formData; as formData) {
      <!-- selected module -->
      <app-summary-section
        heading="MODULE_SELECTION.HEADING_SUMMARY"
        [stepRoute]="routes.MODULE_SELECTION">
        <app-chip>
          {{ 'MODULE_SELECTION.' + formData.selectedModule | translate }}
        </app-chip>
      </app-summary-section>

      @if (areMultipleFlowsEnabled$ | async) {
        <!-- selected flow -->
        <app-summary-section
          heading="FLOW_SELECTION.HEADING"
          [stepRoute]="routes.FLOW_SELECTION">
          <app-chip>
            {{ 'FLOW_SELECTION.' + formData.selectedFlow | translate }}
          </app-chip>
        </app-summary-section>
      }

      <!-- address -->
      <app-summary-section
        heading="ADDRESS.SUBHEADING"
        [stepRoute]="routes.ADDRESS">
        <div class="section-fields-container">
          @if (formData.address) {
            <app-address [address]="formData.address"></app-address>
          }
          @if (formData.address?.streetNotListed) {
            <app-summary-section-field
              label="ADDRESS.FORM.DISTRICT"
              [value]="formData.address?.additionalAddressData?.district">
            </app-summary-section-field>
            <app-summary-section-field
              label="ADDRESS.FORM.PARCEL"
              [value]="formData.address?.additionalAddressData?.parcel">
            </app-summary-section-field>
            <app-summary-section-field
              label="ADDRESS.FORM.PARCEL_NUMBER"
              [value]="formData.address?.additionalAddressData?.parcelNumber">
            </app-summary-section-field>
          }
        </div>
      </app-summary-section>
      <!-- WATER MODULE -->
      @if (formData.selectedModule === module.WATER) {
        <!-- water area -->
        <app-summary-section
          heading="WATER.WATER_AREA.HEADING"
          [stepRoute]="routes.WATER_AREA">
          <app-chip>
            {{ 'WATER.WATER_AREA.' + formData.waterArea | translate }}
          </app-chip>
        </app-summary-section>
        <!-- sewage -->
        @if (formData.waterArea === formType.SEWAGE) {
          <!-- sewage options -->
          <app-summary-section
            heading="WATER.SEWAGE_OPTIONS.HEADING"
            [stepRoute]="routes.SEWAGE_OPTIONS">
            <app-chip>
              {{
                'WATER.SEWAGE_OPTIONS.' +
                  formData.sewageOptions?.sewageOption +
                  '.LABEL' | translate
              }}
            </app-chip>
            @if (
              formData.sewageOptions?.sewageOption === sewageOptions.DETACH
            ) {
              <div class="section-fields-container">
                <app-summary-section-field
                  label="WATER.SEWAGE_OPTIONS.DETACH.DECONSTRUCTION_TYPE_QUESTION"
                  [value]="
                    (formData.sewageOptions?.permanentDeconstruction
                      ? 'WATER.SEWAGE_OPTIONS.DETACH.PERMANENT_DECONSTRUCTION_LABEL'
                      : 'WATER.SEWAGE_OPTIONS.DETACH.TEMPORARY_DECONSTRUCTION_LABEL'
                    ) | translate
                  ">
                </app-summary-section-field>
                @if (!formData.sewageOptions?.permanentDeconstruction) {
                  <app-summary-section-field
                    label="WATER.SEWAGE_OPTIONS.DETACH.DECONSTRUCTION_UNTIL"
                    [value]="
                      (formData.sewageOptions?.deconstructionUntil
                        | date: 'shortDate' : '' : currentLanguageCulture) ||
                      '-'
                    ">
                  </app-summary-section-field>
                }
                <app-summary-section-field
                  label="WATER.SEWAGE_OPTIONS.DETACH.METER_NUMBER"
                  [value]="formData.sewageOptions?.meterNumber">
                </app-summary-section-field>
              </div>
            }
          </app-summary-section>
          <!-- sewage property details -->
          @if (formData.sewageOptions?.sewageOption === sewageOptions.NEW) {
            <app-summary-section
              heading="WATER.WATER_PROPERTY_TYPE.HEADING"
              [stepRoute]="routes.WATER_PROPERTY_TYPE">
              <app-summary-building-type
                [buildingType]="
                  formData.waterBuildingDetails?.buildingType
                "></app-summary-building-type>
              <div class="section-fields-container">
                <app-summary-section-field
                  label="SHARED.BUILDING_STRUCTURE.LABEL"
                  [value]="
                    'SHARED.BUILDING_STRUCTURE.' +
                      formData.waterBuildingDetails?.buildingStructure
                      | translate
                  ">
                </app-summary-section-field>
              </div>
            </app-summary-section>
          }
          @if (formData.sewageOptions?.sewageOption === sewageOptions.NEW) {
            <app-summary-section
              heading="WATER.SEWAGE_TYPE.HEADING"
              [stepRoute]="routes.SEWAGE_TYPE">
              <div class="section-fields-container">
                <app-summary-section-field
                  label="WATER.SEWAGE_TYPE.SEWAGE_TYPE_LABEL"
                  [value]="
                    'WATER.SEWAGE_TYPE.SEWAGE_TYPES.' + formData.sewageType
                      | translate
                  ">
                </app-summary-section-field>
              </div>
            </app-summary-section>
          }
        }
        <!-- construction water requirements -->
        @if (
          formData.waterArea === formType.CONSTRUCTION_WATER &&
          formData.constructionWaterRequirements
        ) {
          <app-summary-section
            heading="WATER.CONSTRUCTION_WATER_REQUIREMENTS.HEADING"
            [stepRoute]="routes.CONSTRUCTION_WATER_REQUIREMENTS">
            <app-construction-water-requirements-summary
              [constructionWaterRequirements]="
                formData.constructionWaterRequirements
              "></app-construction-water-requirements-summary>
          </app-summary-section>
        }
        <!-- water connection -->
        @if (formData.waterArea === formType.WATER) {
          <!-- water options -->
          <app-summary-section
            heading="WATER.WATER_OPTIONS.HEADING"
            [stepRoute]="routes.WATER_OPTIONS">
            <app-chip>
              {{
                'WATER.WATER_OPTIONS.' +
                  formData.waterOptions?.waterOption +
                  '.LABEL' | translate
              }}
            </app-chip>
            @if (formData.waterOptions?.waterOption === waterOptions.CHANGE) {
              @if (formData.waterOptions?.changeFlowRate) {
                <p class="section-heading">
                  {{
                    'WATER.WATER_OPTIONS.CHANGE.CHANGE_FLOW_RATE_LABEL'
                      | translate
                  }}
                </p>
                <div class="section-fields-container">
                  <app-summary-section-field
                    label="WATER.WATER_OPTIONS.CHANGE.CURRENT_FLOW_RATE_PLACEHOLDER"
                    [value]="formData.waterOptions?.currentFlowRate">
                  </app-summary-section-field>
                  <app-summary-section-field
                    label="WATER.WATER_OPTIONS.CHANGE.PLANNED_FLOW_RATE_PLACEHOLDER"
                    [value]="formData.waterOptions?.plannedFlowRate">
                  </app-summary-section-field>
                  <app-summary-section-field
                    label="WATER.WATER_OPTIONS.CHANGE.ADDITIONAL_DEMAND_QUESTION"
                    [value]="formData.waterOptions?.additionalDemand">
                  </app-summary-section-field>
                </div>
              }
              @if (formData.waterOptions?.relocate) {
                <p class="section-heading">
                  {{ 'WATER.WATER_OPTIONS.CHANGE.RELOCATE_LABEL' | translate }}
                </p>
                <div class="section-fields-container">
                  <app-summary-section-field
                    label="WATER.WATER_OPTIONS.CHANGE.DITCH_LENGTH_PLACEHOLDER"
                    [value]="formData.waterOptions?.ditchLength">
                  </app-summary-section-field>
                </div>
              }
            }
            @if (formData.waterOptions?.waterOption === waterOptions.DETACH) {
              <div class="section-fields-container">
                <app-summary-section-field
                  label="WATER.WATER_OPTIONS.DETACH.DECONSTRUCTION_TYPE_QUESTION"
                  [value]="
                    (formData.waterOptions?.permanentDeconstruction
                      ? 'WATER.WATER_OPTIONS.DETACH.PERMANENT_DECONSTRUCTION_LABEL'
                      : 'WATER.WATER_OPTIONS.DETACH.TEMPORARY_DECONSTRUCTION_LABEL'
                    ) | translate
                  ">
                </app-summary-section-field>
                @if (!formData.waterOptions?.permanentDeconstruction) {
                  <app-summary-section-field
                    label="WATER.WATER_OPTIONS.DETACH.DECONSTRUCTION_UNTIL"
                    [value]="
                      (formData.waterOptions?.deconstructionUntil
                        | date: 'shortDate' : '' : currentLanguageCulture) ||
                      '-'
                    ">
                  </app-summary-section-field>
                }
                <app-summary-section-field
                  label="WATER.WATER_OPTIONS.DETACH.METER_NUMBER"
                  [value]="formData.waterOptions?.meterNumber">
                </app-summary-section-field>
              </div>
            }
          </app-summary-section>
          <!-- water property details -->
          @if (
            formData.waterOptions?.waterOption === waterOptions.NEW ||
            formData.waterOptions?.waterOption === waterOptions.CHANGE
          ) {
            <app-summary-section
              heading="WATER.WATER_PROPERTY_TYPE.HEADING"
              [stepRoute]="routes.WATER_PROPERTY_TYPE">
              <app-summary-building-type
                [buildingType]="
                  formData.waterBuildingDetails?.buildingType
                "></app-summary-building-type>
              <div class="section-fields-container">
                <app-summary-section-field
                  label="SHARED.BUILDING_STRUCTURE.LABEL"
                  [value]="
                    'SHARED.BUILDING_STRUCTURE.' +
                      formData.waterBuildingDetails?.buildingStructure
                      | translate
                  ">
                </app-summary-section-field>
              </div>
            </app-summary-section>
          }
          <!-- water requirements -->
          @if (formData.waterOptions?.waterOption === waterOptions.NEW) {
            <app-summary-section
              heading="WATER.WATER_REQUIREMENTS.HEADING"
              [stepRoute]="routes.WATER_REQUIREMENTS">
              <p class="section-heading">
                {{
                  'WATER.WATER_REQUIREMENTS.SUBHEADING_CONSUMPTION' | translate
                }}
              </p>
              <div class="section-fields-container">
                <app-summary-section-field
                  label="WATER.WATER_REQUIREMENTS.NUMBER_OF_HOUSING_UNITS"
                  [value]="
                    formData.waterRequirementDetails?.numberOfHousingUnits
                  ">
                </app-summary-section-field>
                <app-summary-section-field
                  label="WATER.WATER_REQUIREMENTS.NUMBER_OF_RESIDENTS"
                  [value]="formData.waterRequirementDetails?.numberOfResidents">
                </app-summary-section-field>
              </div>
              <p class="section-heading">
                {{
                  'WATER.WATER_REQUIREMENTS.SUBHEADING_MORE_REQUIREMENTS'
                    | translate
                }}
              </p>
              <div class="section-fields-container">
                <app-summary-section-field
                  label="WATER.WATER_REQUIREMENTS.NUMBER_OF_FLOORS"
                  [value]="formData.waterRequirementDetails?.numberOfFloors">
                </app-summary-section-field>
                <app-summary-section-field
                  label="WATER.WATER_REQUIREMENTS.EXTRACTION_POINT_HEIGHT"
                  [value]="
                    formData.waterRequirementDetails?.extractionPointHeight
                  ">
                </app-summary-section-field>
                <app-summary-section-field
                  label="WATER.WATER_REQUIREMENTS.FLOW_RATE"
                  [value]="formData.waterRequirementDetails?.flowRate">
                </app-summary-section-field>
                <app-summary-section-field
                  label="WATER.WATER_REQUIREMENTS.SUBHEADING_ADDITIONAL_CONSUMERS"
                  [value]="
                    formData.waterRequirementDetails?.additionalConsumers
                  ">
                </app-summary-section-field>
              </div>
            </app-summary-section>
          }
        }
      }
      <!-- GAS MODULE -->
      @if (formData.selectedModule === module.GAS) {
        <!-- gas options -->
        <app-summary-section
          heading="GAS.GAS_OPTIONS.HEADING"
          [stepRoute]="routes.GAS_OPTIONS">
          <app-chip>
            {{
              'GAS.GAS_OPTIONS.' + formData.gasOptions?.gasOption + '.LABEL'
                | translate
            }}
          </app-chip>
          @if (formData.gasOptions?.gasOption === gasOptions.CHANGE) {
            @if (formData.gasOptions?.changePower) {
              <p class="section-heading">
                {{ 'GAS.GAS_OPTIONS.CHANGE.CHANGE_POWER_LABEL' | translate }}
              </p>
              <div class="section-fields-container">
                <app-summary-section-field
                  label="GAS.GAS_OPTIONS.CHANGE.CURRENT_POWER_DEMAND_PLACEHOLDER"
                  [value]="formData.gasOptions?.currentPowerDemand">
                </app-summary-section-field>
                <app-summary-section-field
                  label="GAS.GAS_OPTIONS.CHANGE.PLANNED_POWER_DEMAND_PLACEHOLDER"
                  [value]="formData.gasOptions?.plannedPowerDemand">
                </app-summary-section-field>
              </div>
            }
            @if (formData.gasOptions?.relocate) {
              <p class="section-heading">
                {{ 'GAS.GAS_OPTIONS.CHANGE.RELOCATE_LABEL' | translate }}
              </p>
              <div class="section-fields-container">
                <app-summary-section-field
                  label="GAS.GAS_OPTIONS.CHANGE.DITCH_LENGTH_PLACEHOLDER"
                  [value]="formData.gasOptions?.ditchLength">
                </app-summary-section-field>
              </div>
            }
          }
          @if (formData.gasOptions?.gasOption === gasOptions.DETACH) {
            <div class="section-fields-container">
              <app-summary-section-field
                label="GAS.GAS_OPTIONS.DETACH.PARTIAL_DECONSTRUCTION_DESIRED_QUESTION"
                [value]="
                  (formData.gasOptions?.partialDeconstructionDesired
                    ? 'COMMON.YES'
                    : 'COMMON.NO'
                  ) | translate
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="GAS.GAS_OPTIONS.DETACH.METER_NUMBER"
                [value]="formData.gasOptions?.meterNumber">
              </app-summary-section-field>
            </div>
          }
        </app-summary-section>
        <!-- gas property details -->
        @if (
          !(
            formData.gasOptions?.gasOption === gasOptions.CHANGE &&
            !formData.gasOptions?.relocate
          ) && formData.gasOptions?.gasOption !== gasOptions.DETACH
        ) {
          <app-summary-section
            heading="GAS.GAS_PROPERTY_TYPE.HEADING"
            [stepRoute]="routes.GAS_PROPERTY_TYPE">
            <app-summary-building-type
              [buildingType]="
                formData.gasBuildingDetails?.buildingType
              "></app-summary-building-type>
            <div class="section-fields-container">
              <app-summary-section-field
                label="SHARED.BUILDING_STRUCTURE.LABEL"
                [value]="
                  'SHARED.BUILDING_STRUCTURE.' +
                    formData.gasBuildingDetails?.buildingStructure | translate
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="GAS.GAS_PROPERTY_TYPE.FORM.CURRENT_ENERGY_SOURCE.LABEL"
                [value]="
                  formData.gasBuildingDetails?.currentEnergySource
                    ? ('GAS.GAS_PROPERTY_TYPE.FORM.CURRENT_ENERGY_SOURCE.' +
                        formData.gasBuildingDetails?.currentEnergySource
                      | translate)
                    : '-'
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="SHARED.HOUSE_INLET.LABEL"
                [value]="
                  formData.gasBuildingDetails?.houseInlet
                    ? ('SHARED.HOUSE_INLET.' +
                        formData.gasBuildingDetails?.houseInlet | translate)
                    : '-'
                ">
              </app-summary-section-field>
            </div>
          </app-summary-section>
        }
        <!-- gas power requirement -->
        @if (formData.gasOptions?.gasOption === gasOptions.NEW) {
          <app-summary-section
            heading="GAS.GAS_POWER_REQUIREMENT.HEADING"
            [stepRoute]="routes.GAS_POWER_REQUIREMENT">
            <div class="section-fields-container">
              <app-summary-section-field
                label="GAS.GAS_POWER_REQUIREMENT.POWER_REQUIREMENT_PLACEHOLDER"
                [value]="formData.gasPowerRequirement?.powerDemand">
              </app-summary-section-field>
              <app-summary-section-field
                label="GAS.GAS_POWER_REQUIREMENT.ANYTHING_ELSE_QUESTION"
                [value]="formData.gasPowerRequirement?.notes">
              </app-summary-section-field>
            </div>
          </app-summary-section>
        }
      }
      <!-- ELECTRICITY MODULE -->
      @if (
        formData.selectedModule === module.ELECTRICITY && formData.formType
      ) {
        <!-- request -->
        <app-summary-section
          heading="SUMMARY.REQUEST"
          [stepRoute]="routes.ELECTRICITY_PRODUCT_SELECTION">
          <app-chip>
            {{ 'PRODUCTS.' + formData.formType | translate }}
          </app-chip>
        </app-summary-section>
        <!-- PV type -->
        @if (formData.formType === formType.PV) {
          <app-summary-section
            heading="PV_TYPE.HEADING"
            [stepRoute]="routes.PV_TYPE">
            <app-chip>
              {{ 'PV_TYPE.TYPE.' + formData.pvType | translate }}
            </app-chip>
          </app-summary-section>
        }
        <!-- pv facility information  -->
        @if (
          formData.formType === formType.PV &&
          formData.pvType === pvType.NEW &&
          formData.pvFacilityInformation
        ) {
          <app-summary-section
            heading="PV_FACILITY_INFORMATION.HEADING"
            [stepRoute]="routes.PV_FACILITY_INFORMATION">
            <div class="section-fields-container">
              <app-summary-section-field
                label="PV_FACILITY_INFORMATION.OPERATING_MODE.LABEL"
                [value]="
                  'SHARED.PV.OPERATING_MODE.' +
                    formData.pvFacilityInformation.operatingMode | translate
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="PV_FACILITY_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_TYPE.LABEL"
                [value]="
                  'PV_FACILITY_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_TYPE.OPTION.' +
                    formData.pvFacilityInformation
                      .networkAndSystemProtectionType | translate
                ">
              </app-summary-section-field>
            </div>
            <p class="section-heading">
              {{
                'PV_FACILITY_INFORMATION.GENERATING_FACILITY_DATA.SUBHEADING'
                  | translate
              }}
            </p>
            <div class="section-fields-container">
              <app-summary-section-field
                label="PV_FACILITY_INFORMATION.GENERATING_FACILITY_DATA.MANUFACTURER"
                [value]="formData.pvFacilityInformation.manufacturer">
              </app-summary-section-field>
              <app-summary-section-field
                label="PV_FACILITY_INFORMATION.GENERATING_FACILITY_DATA.TYPE"
                [value]="formData.pvFacilityInformation.type">
              </app-summary-section-field>
              <app-summary-section-field
                innerHTMLLabel="PV_FACILITY_INFORMATION.GENERATING_FACILITY_DATA.MAX_APPARENT_POWER"
                [value]="formData.pvFacilityInformation.maxApparentPower">
              </app-summary-section-field>
            </div>
            <p class="section-heading">
              {{
                'PV_FACILITY_INFORMATION.GENERATING_UNIT_DATA.SUBHEADING'
                  | translate
              }}
            </p>
            <div class="section-fields-container">
              <app-summary-section-field
                label="PV_FACILITY_INFORMATION.GENERATING_UNIT_DATA.INVERTER_COUNT"
                [value]="formData.pvFacilityInformation.inverterCount">
              </app-summary-section-field>
              <app-summary-section-field
                innerHTMLLabel="PV_FACILITY_INFORMATION.GENERATING_UNIT_DATA.MAX_APPARENT_POWER_SUM"
                [value]="formData.pvFacilityInformation.maxApparentPowerSum">
              </app-summary-section-field>
              <app-summary-section-field
                label="PV_FACILITY_INFORMATION.GENERATING_UNIT_DATA.PV_MODULE_COUNT"
                [value]="formData.pvFacilityInformation.pvModuleCount">
              </app-summary-section-field>
              <app-summary-section-field
                label="PV_FACILITY_INFORMATION.GENERATING_UNIT_DATA.POWER_PER_PV_MODULE"
                [value]="formData.pvFacilityInformation.powerPerPVModule">
              </app-summary-section-field>
            </div>
            <div class="section-fields-container">
              <app-summary-section-field
                label="HEAT_PUMP.METER_REQUIRED"
                [value]="
                  (formData.pvFacilityInformation.meterRequired
                    ? 'COMMON.YES'
                    : 'COMMON.NO'
                  ) | translate
                ">
              </app-summary-section-field>
              @if (!formData.pvFacilityInformation.meterRequired) {
                <app-summary-section-field
                  label="HEAT_PUMP.METER_NUMBER"
                  [value]="formData.pvFacilityInformation.meterNumber">
                </app-summary-section-field>
              }
              <app-summary-section-field
                label="PV_FACILITY_INFORMATION.FORM_OF_FEED_IN_SALE.LABEL"
                [value]="
                  'SHARED.PV.FORM_OF_FEED_IN_SALE.' +
                    formData.pvFacilityInformation.formOfFeedInSale | translate
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="PV_FACILITY_INFORMATION.STORAGE_PLANNED_LABEL"
                [value]="
                  (formData.pvFacilityInformation.storagePlanned
                    ? 'COMMON.YES'
                    : 'COMMON.NO'
                  ) | translate
                ">
              </app-summary-section-field>
            </div>
          </app-summary-section>
        }
        <!-- change PV details  -->
        @if (
          formData.formType === formType.PV &&
          formData.pvType === pvType.CHANGE &&
          formData.changePVDetails
        ) {
          <app-summary-section
            heading="CHANGE_PV_DETAILS.HEADING"
            [stepRoute]="routes.CHANGE_PV_DETAILS">
            <p class="section-heading">
              {{ 'CHANGE_PV_DETAILS.OPERATING_MODE_TITLE' | translate }}
            </p>
            <div class="section-fields-container">
              <app-summary-section-field
                label="CHANGE_PV_DETAILS.CURRENT_OPERATING_MODE_TITLE"
                [value]="
                  'SHARED.PV.OPERATING_MODE.' +
                    formData.changePVDetails.currentOperatingMode | translate
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="CHANGE_PV_DETAILS.PLANNED_OPERATING_MODE_TITLE"
                [value]="
                  'SHARED.PV.OPERATING_MODE.' +
                    formData.changePVDetails.plannedOperatingMode | translate
                ">
              </app-summary-section-field>
            </div>
            <p class="section-heading">
              {{ 'CHANGE_PV_DETAILS.FORM_OF_FEED_IN_SALE_TITLE' | translate }}
            </p>
            <div class="section-fields-container">
              <app-summary-section-field
                label="CHANGE_PV_DETAILS.CURRENT_FORM_OF_FEED_IN_SALE_TITLE"
                [value]="
                  'SHARED.PV.FORM_OF_FEED_IN_SALE.' +
                    formData.changePVDetails.currentFormOfFeedInSale | translate
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="CHANGE_PV_DETAILS.PLANNED_FORM_OF_FEED_IN_SALE_TITLE"
                [value]="
                  'SHARED.PV.FORM_OF_FEED_IN_SALE.' +
                    formData.changePVDetails.plannedFormOfFeedInSale | translate
                ">
              </app-summary-section-field>
            </div>
            <app-summary-section-field
              label="CHANGE_PV_DETAILS.OTHER_CHANGE"
              [value]="formData.changePVDetails.notes">
            </app-summary-section-field>
          </app-summary-section>
        }
        <!-- pv storage information  -->
        @if (
          formData.formType === formType.PV &&
          formData.pvFacilityInformation?.storagePlanned &&
          formData.storageDetails
        ) {
          <app-summary-section
            heading="STORAGE_DETAILS.HEADING"
            [stepRoute]="routes.STORAGE_INFORMATION">
            <p class="section-heading">
              {{ 'STORAGE_DETAILS.STORAGE_UNIT_DATA.SUBHEADING' | translate }}
            </p>
            <div class="section-fields-container">
              <app-summary-section-field
                label="STORAGE_DETAILS.STORAGE_UNIT_DATA.MANUFACTURER"
                [value]="formData.storageDetails.manufacturer">
              </app-summary-section-field>
              <app-summary-section-field
                label="STORAGE_DETAILS.STORAGE_UNIT_DATA.TYPE"
                [value]="formData.storageDetails.type">
              </app-summary-section-field>
              <app-summary-section-field
                label="STORAGE_DETAILS.STORAGE_UNIT_DATA.STORAGE_UNIT_COUNT"
                [value]="formData.storageDetails.storageUnitCount">
              </app-summary-section-field>
              <app-summary-section-field
                innerHTMLLabel="STORAGE_DETAILS.STORAGE_UNIT_DATA.MAX_CONTROLLABLE_POWER"
                [value]="formData.storageDetails.maxControllablePower">
              </app-summary-section-field>
              <app-summary-section-field
                innerHTMLLabel="STORAGE_DETAILS.STORAGE_UNIT_DATA.MAX_APPARENT_POWER_SUM"
                [value]="formData.storageDetails.maxApparentPowerSum">
              </app-summary-section-field>
            </div>
            <div class="section-fields-container">
              <app-summary-section-field
                label="STORAGE_DETAILS.UNIT_COUPLING.LABEL"
                [value]="
                  'STORAGE_DETAILS.UNIT_COUPLING.OPTION.' +
                    formData.storageDetails.unitCoupling | translate
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="STORAGE_DETAILS.OPERATING_MODE.LABEL"
                [value]="
                  'STORAGE_DETAILS.OPERATING_MODE.OPTION.' +
                    formData.storageDetails.operatingMode | translate
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="STORAGE_DETAILS.MEASUREMENT_CONCEPT.LABEL"
                [value]="
                  formData.storageDetails.measurementConcept
                    ? ('STORAGE_DETAILS.MEASUREMENT_CONCEPT.OPTION.' +
                        formData.storageDetails.measurementConcept | translate)
                    : '-'
                ">
              </app-summary-section-field>
            </div>
          </app-summary-section>
        }
        <!-- charging device options -->
        @if (formData.formType === formType.CHARGING_DEVICE) {
          <app-summary-section
            heading="CHARGING_DEVICE_OPTIONS.HEADING"
            [stepRoute]="routes.CHARGING_DEVICE_OPTIONS">
            <div class="section-fields-container">
              <app-summary-section-field
                label="CHARGING_DEVICE_OPTIONS.FORM.TYPE_OF_CHARGING_DEVICE"
                [value]="
                  'CHARGING_DEVICE_OPTIONS.FORM.' +
                    formData.chargingDeviceOptions?.chargingDeviceType
                    | translate
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="CHARGING_DEVICE_OPTIONS.FORM.METER_REQUIRED"
                [value]="
                  (formData.chargingDeviceOptions?.meterRequired
                    ? 'COMMON.YES'
                    : 'COMMON.NO'
                  ) | translate
                ">
              </app-summary-section-field>
              @if (!formData.chargingDeviceOptions?.meterRequired) {
                <app-summary-section-field
                  label="CHARGING_DEVICE_OPTIONS.FORM.METER_NUMBER"
                  [value]="formData.chargingDeviceOptions?.meterNumber">
                </app-summary-section-field>
              }
              <app-summary-section-field
                label="SHARED.CONTROLLABLE_POWER.LABEL"
                [value]="
                  'SHARED.CONTROLLABLE_POWER.OPTION.' +
                    formData.chargingDeviceOptions?.controllablePower
                    | translate
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="CHARGING_DEVICE_OPTIONS.FORM.NUMBER_OF_CHARGING_DEVICES"
                [value]="formData.chargingDeviceOptions?.count">
              </app-summary-section-field>
              <app-summary-section-field
                label="CHARGING_DEVICE_OPTIONS.FORM.NUMBER_OF_CHARGING_POINTS"
                [value]="
                  formData.chargingDeviceOptions
                    ?.chargingPointsPerChargingDeviceCount
                ">
              </app-summary-section-field>
            </div>
          </app-summary-section>
        }
        <!-- charging device more options -->
        @if (formData.formType === formType.CHARGING_DEVICE) {
          <app-summary-section
            heading="CHARGING_DEVICE_MORE_OPTIONS.HEADING"
            [stepRoute]="routes.CHARGING_DEVICE_MORE_OPTIONS">
            <div class="section-fields-container">
              <app-summary-section-field
                label="CHARGING_DEVICE_MORE_OPTIONS.POWER_PER_CHARGING_DEVICE"
                [value]="
                  formData.chargingDeviceMoreOptions?.powerPerChargingDevice
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="CHARGING_DEVICE_MORE_OPTIONS.MAXIMUM_GRID_POWER"
                [value]="formData.chargingDeviceMoreOptions?.maximumGridPower">
              </app-summary-section-field>
              <app-summary-section-field
                label="CHARGING_DEVICE_MORE_OPTIONS.LOAD_MANAGEMENT.LABEL"
                [value]="
                  'CHARGING_DEVICE_MORE_OPTIONS.LOAD_MANAGEMENT.OPTION.' +
                    formData.chargingDeviceMoreOptions?.loadManagement
                    | translate
                ">
              </app-summary-section-field>
            </div>
          </app-summary-section>
        }
        <!-- balcony pv -->
        @if (formData.formType === formType.BALCONY_PV) {
          <app-summary-section
            heading="BALCONY_PV.HEADING"
            [stepRoute]="routes.BALCONY_PV">
            <p class="section-heading">
              {{ 'BALCONY_PV.SYSTEM_BASIC_INFORMATION' | translate }}
            </p>
            <div class="section-fields-container">
              <app-summary-section-field
                label="BALCONY_PV.SYSTEM"
                [value]="formData.balconyPV?.system">
              </app-summary-section-field>
              <app-summary-section-field
                label="BALCONY_PV.SYSTEM_MANUFACTURER"
                [value]="formData.balconyPV?.systemManufacturer ?? '-'">
              </app-summary-section-field>
              <app-summary-section-field
                label="BALCONY_PV.SYSTEM_TYPE"
                [value]="formData.balconyPV?.systemType">
              </app-summary-section-field>
            </div>
            <div class="section-fields-container">
              <app-summary-section-field
                label="BALCONY_PV.SYSTEM_POWER_LABEL"
                [value]="formData.balconyPV?.systemPower">
              </app-summary-section-field>
              <app-summary-section-field
                label="BALCONY_PV.INVERTER_POWER_LABEL"
                [value]="formData.balconyPV?.inverterPower">
              </app-summary-section-field>
              <app-summary-section-field
                label="BALCONY_PV.FREE_FEED_IN_DESIRED"
                [value]="
                  (formData.balconyPV?.freeFeedIn ? 'COMMON.YES' : 'COMMON.NO')
                    | translate
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="BALCONY_PV.METER_NUMBER"
                [value]="formData.balconyPV?.meterNumber">
              </app-summary-section-field>
              <app-summary-section-field
                label="BALCONY_PV.ANYTHING_ELSE"
                [value]="formData.balconyPV?.notes">
              </app-summary-section-field>
            </div>
          </app-summary-section>
        }
        <!-- grid connection type -->
        @if (formData.formType === formType.GRID_CONNECTION) {
          <app-summary-section
            heading="GRID_CONNECTION_TYPE.HEADING"
            [stepRoute]="routes.GRID_CONNECTION_TYPE">
            <app-chip>
              {{
                'GRID_CONNECTION_TYPE.FORM.' + formData.gridConnectionType
                  | translate
              }}
            </app-chip>
          </app-summary-section>
        }
        <!-- heat pump -->
        @if (formData.formType === formType.HEAT_PUMP) {
          <app-summary-section
            heading="HEAT_PUMP.HEADING"
            [stepRoute]="routes.HEAT_PUMP">
            <p class="section-heading">
              {{ 'HEAT_PUMP.SYSTEM_BASIC_INFORMATION' | translate }}
            </p>
            <div class="section-fields-container">
              <app-summary-section-field
                label="HEAT_PUMP.SYSTEM_MANUFACTURER"
                [value]="formData.heatPump?.systemManufacturer">
              </app-summary-section-field>
              <app-summary-section-field
                label="HEAT_PUMP.SYSTEM_TYPE"
                [value]="formData.heatPump?.systemType">
              </app-summary-section-field>
            </div>
            <div class="section-fields-container">
              <app-summary-section-field
                label="HEAT_PUMP.SYSTEM_POWER_LABEL"
                [value]="formData.heatPump?.systemPower">
              </app-summary-section-field>
              <app-summary-section-field
                label="HEAT_PUMP.METER_REQUIRED"
                [value]="
                  (formData.heatPump?.meterRequired
                    ? 'COMMON.YES'
                    : 'COMMON.NO'
                  ) | translate
                ">
              </app-summary-section-field>
              @if (!formData.heatPump?.meterRequired) {
                <app-summary-section-field
                  label="HEAT_PUMP.METER_NUMBER"
                  [value]="formData.heatPump?.meterNumber">
                </app-summary-section-field>
              }
              <app-summary-section-field
                label="HEAT_PUMP.CONNECTION_POWER_INCREASE"
                [value]="
                  (formData.heatPump?.connectionPowerIncrease
                    ? 'COMMON.YES'
                    : 'COMMON.NO'
                  ) | translate
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="SHARED.CONTROLLABLE_POWER.LABEL"
                [value]="
                  'SHARED.CONTROLLABLE_POWER.OPTION.' +
                    formData.heatPump?.controllablePower | translate
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="HEAT_PUMP.ANYTHING_ELSE"
                [value]="formData.heatPump?.notes">
              </app-summary-section-field>
            </div>
            @if (formData.heatPump?.connectionPowerIncrease) {
              <p class="section-heading">
                {{ 'HEAT_PUMP.CURRENT_POWER_DEMAND' | translate }}
              </p>
              <div class="section-fields-container">
                <app-summary-section-field
                  label="HEAT_PUMP.CURRENT_POWER_DEMAND_LABEL"
                  [value]="formData.heatPump?.currentPowerDemand">
                </app-summary-section-field>
                <app-summary-section-field
                  label="HEAT_PUMP.PLANNED_POWER_DEMAND"
                  [value]="formData.heatPump?.plannedPowerDemand">
                </app-summary-section-field>
              </div>
            }
          </app-summary-section>
        }
        <!-- construction electricity -->
        @if (formData.formType === formType.CONSTRUCTION_ELECTRICITY) {
          <app-summary-section
            heading="CONSTRUCTION_ELECTRICITY_INFORMATION.HEADING"
            [stepRoute]="routes.CONSTRUCTION_ELECTRICITY">
            <app-construction-electricity-details-summary
              [constructionElectricityDetails]="
                formData.constructionElectricity!
              "></app-construction-electricity-details-summary>
          </app-summary-section>
        }
        <!-- other product -->
        @if (formData.formType === formType.OTHER) {
          <app-summary-section
            heading="OTHER.HEADING"
            [stepRoute]="routes.OTHER">
            <div class="section-fields-container">
              <app-summary-section-field
                label="OTHER.TYPE_OF_REQUEST"
                [value]="formData.other?.typeOfRequest">
              </app-summary-section-field>
              <app-summary-section-field
                label="OTHER.SYSTEM_POWER_LABEL"
                [value]="formData.other?.systemPower">
              </app-summary-section-field>
              <app-summary-section-field
                label="OTHER.METER_NUMBER"
                [value]="formData.other?.meterNumber">
              </app-summary-section-field>
              <app-summary-section-field
                label="OTHER.ANYTHING_ELSE"
                [value]="formData.other?.notes">
              </app-summary-section-field>
            </div>
          </app-summary-section>
        }
        <!-- charging device building type -->
        @if (formData.formType === formType.CHARGING_DEVICE) {
          <app-summary-section
            heading="SUMMARY.BUILDING"
            [stepRoute]="routes.CHARGING_DEVICE_PROPERTY_TYPE">
            <app-summary-building-type
              [buildingType]="
                formData.chargingDevicePropertyType
              "></app-summary-building-type>
          </app-summary-section>
        }
        <!-- new grid connection building type -->
        @if (
          formData.formType === formType.GRID_CONNECTION &&
          formData.gridConnectionType === gridConnectionType.NEW
        ) {
          <app-summary-section
            heading="SUMMARY.BUILDING"
            [stepRoute]="routes.GRID_CONNECTION_PROPERTY_TYPE">
            <app-summary-building-type
              [buildingType]="
                formData.newGridConnectionDetails?.buildingType
              "></app-summary-building-type>
            <div class="section-fields-container">
              @if (
                formData.newGridConnectionDetails?.buildingType ===
                buildingType.MULTI_FAMILY_HOUSE
              ) {
                <app-summary-section-field
                  label="GRID_CONNECTION_PROPERTY_TYPE.FORM.FLAT_COUNT"
                  [value]="formData.newGridConnectionDetails?.flatCount">
                </app-summary-section-field>
              }
              @if (
                formData.newGridConnectionDetails?.buildingType ===
                  buildingType.INDUSTRY ||
                formData.newGridConnectionDetails?.buildingType ===
                  buildingType.MIXED
              ) {
                <app-summary-section-field
                  label="GRID_CONNECTION_PROPERTY_TYPE.FORM.AREA"
                  [value]="formData.newGridConnectionDetails?.area">
                </app-summary-section-field>
              }
              <app-summary-section-field
                label="GRID_CONNECTION_PROPERTY_TYPE.FORM.BASEMENT_QUESTION"
                [value]="
                  (formData.newGridConnectionDetails?.basementAvailable
                    ? 'COMMON.YES'
                    : 'COMMON.NO'
                  ) | translate
                ">
              </app-summary-section-field>
              <app-summary-section-field
                label="GRID_CONNECTION_PROPERTY_TYPE.FORM.EXTERNAL_CONNECTION_QUESTION"
                [value]="
                  (formData.newGridConnectionDetails?.externalConnection
                    ? 'COMMON.YES'
                    : 'COMMON.NO'
                  ) | translate
                ">
              </app-summary-section-field>
            </div>
          </app-summary-section>
        }
        @if (
          formData.formType === formType.GRID_CONNECTION &&
          formData.gridConnectionType === gridConnectionType.CHANGE
        ) {
          <!-- grid connection change -->
          <app-summary-section
            heading="GRID_CONNECTION_CHANGE.HEADING"
            [stepRoute]="routes.GRID_CONNECTION_CHANGE">
            <app-chip>
              {{
                'GRID_CONNECTION_CHANGE.CHANGE_TYPE.' +
                  formData.changeGridConnectionDetails?.changeType | translate
              }}
            </app-chip>
            <div class="section-fields-container">
              <app-summary-section-field
                label="SUMMARY.DITCH_LENGTH"
                [value]="formData.changeGridConnectionDetails?.ditchLength">
              </app-summary-section-field>
              <app-summary-section-field
                label="GRID_CONNECTION_CHANGE.TOTAL_POWER_CHANGED"
                [value]="
                  (formData.changeGridConnectionDetails?.totalPowerChanged
                    ? 'COMMON.YES'
                    : 'COMMON.NO'
                  ) | translate
                ">
              </app-summary-section-field>
            </div>
          </app-summary-section>
          <!-- grid connection power change -->
          @if (formData.changeGridConnectionDetails?.totalPowerChanged) {
            <ng-template
              [ngTemplateOutlet]="gridConnectionPowerChange"></ng-template>
          }
        }
        <!-- grid connection power change -->
        @if (
          formData.formType === formType.GRID_CONNECTION &&
          formData.gridConnectionType === gridConnectionType.POWER
        ) {
          <ng-template
            [ngTemplateOutlet]="gridConnectionPowerChange"></ng-template>
        }
        <!-- planned facilities for new grid connection -->
        @if (
          formData.formType === formType.GRID_CONNECTION &&
          formData.gridConnectionType === gridConnectionType.NEW
        ) {
          <app-summary-section
            heading="SUMMARY.PLANNED_FACILITIES"
            [stepRoute]="routes.GRID_CONNECTION_CONSUMER">
            <span class="consumer-container">
              @if (formData.gridConnectionConsumer?.type?.HEAT_PUMP) {
                <app-chip>
                  {{ 'SHARED.CONSUMER.HEAT_PUMP' | translate }}
                </app-chip>
              }
              @if (formData.gridConnectionConsumer?.type?.FLOW_HEATER) {
                <app-chip>
                  {{ 'SHARED.CONSUMER.FLOW_HEATER' | translate }}
                </app-chip>
              }
              @if (formData.gridConnectionConsumer?.type?.WALLBOX) {
                <app-chip>
                  {{ 'SHARED.CONSUMER.WALLBOX' | translate }}
                </app-chip>
              }
            </span>
            <div class="section-fields-container">
              <app-summary-section-field
                label="SHARED.CONSUMER.SYSTEM_POWER"
                [value]="formData.gridConnectionConsumer?.systemPower">
              </app-summary-section-field>
              <app-summary-section-field
                label="SHARED.CONSUMER.CONNECTION_FUSE"
                [value]="formData.gridConnectionConsumer?.connectionFuse">
              </app-summary-section-field>
              <app-summary-section-field
                label="GRID_CONNECTION_CONSUMER.MAX_CONCURRENT_POWER"
                [value]="formData.gridConnectionConsumer?.maxConcurrentPower">
              </app-summary-section-field>
              <app-summary-section-field
                label="SHARED.CONSUMER.OTHER_CONSUMER_LABEL"
                [value]="formData.gridConnectionConsumer?.additionalConsumers">
              </app-summary-section-field>
            </div>
          </app-summary-section>
        }
      }
      <!-- STORAGE_2 -->
      @if (formData.formType === formType.STORAGE_2) {
        @if (formData.storageCustomerFacility) {
          <app-storage-customer-facility-summary
            [storageCustomerFacility]="
              formData.storageCustomerFacility
            "></app-storage-customer-facility-summary>
        }
        @if (
          formData.storageCustomerFacility?.controllable &&
          formData.storageControllableDetails
        ) {
          <app-storage-controllable-system-summary
            [controllableDetails]="formData.storageControllableDetails"
            [networkAndSystemProtectionDetails]="
              formData.storageNetworkAndSystemProtectionDetails
            "></app-storage-controllable-system-summary>
        }
        @if (formData.storageDetailsDto) {
          <app-storage-details-summary
            [storageDetails]="
              formData.storageDetailsDto
            "></app-storage-details-summary>
        }
      }

      <!-- COMMISSIONING -->
      @if (
        formData.selectedFlow === FLOW.COMMISSIONING &&
        formData.commissioningFlowType
      ) {
        @if (formData?.selectedModule) {
          <app-summary-section
            heading="SUMMARY.REQUEST"
            [stepRoute]="
              commissioningFlowSelectionRoutes.get(formData.selectedModule!!)
            ">
            <app-chip>
              {{
                'COMMISSIONING_FLOW_TYPE.' + formData.commissioningFlowType
                  | translate
              }}
            </app-chip>
          </app-summary-section>
        }
        @switch (formData.commissioningFlowType) {
          @case (COMMISSIONING_FLOW_TYPE.MAIN_POWER_SUPPLY) {
            @if (formData.mainPowerSupplyType) {
              <app-main-power-supply-summary
                [mainPowerSupplyType]="formData.mainPowerSupplyType!"
                [buildingDetails]="
                  formData.commissioningElectricityBuildingDetails
                "
                [electricityInformation]="
                  formData.commissioningElectricityInformation
                "
                [powerIncreaseDetails]="
                  formData.mainPowerSupplyPowerIncreaseDetails
                "></app-main-power-supply-summary>
            }
          }
          @case (COMMISSIONING_FLOW_TYPE.CUSTOMER_FACILITY) {
            @if (formData.commissioningElectricitySystems) {
              <app-customer-facility-summary
                [electricitySystems]="formData.commissioningElectricitySystems"
                [meterChanges]="
                  formData.commissioningElectricitySystems.meterChanges
                "
                [simpleMeasurementDetails]="
                  formData.commissioningElectricitySimpleMeasurementDetails
                "
                [complexMeasurementDetails]="
                  formData.commissioningElectricityMeasurementDetails
                "></app-customer-facility-summary>
            }
          }
        }
      }
      <!-- HEAT MODULE -->
      @if (formData.selectedModule === module.HEAT) {
        <app-heat-summary [formData]="formData"></app-heat-summary>
      }
      <!-- document upload -->
      @if ((whiteLabelConfig$ | async)?.documentsUploadEnabled) {
        <app-summary-section
          heading="DOCUMENTS_UPLOAD.HEADING"
          [stepRoute]="routes.DOCUMENTS_UPLOAD">
          @if (formData.documentDetails?.documents?.length) {
            <div class="upload-document-section">
              @for (
                document of formData.documentDetails?.documents;
                track document
              ) {
                <div class="document-container">
                  <mat-icon
                    aria-hidden="false"
                    aria-label="file icon"
                    fontIcon="attach_file"
                    class="attach-file-icon material-icons-outlined">
                  </mat-icon>
                  <span class="bold">{{ document.name }}</span>
                </div>
              }
            </div>
          } @else {
            <p class="no-documents-uploaded">
              {{ 'SUMMARY.NO_DOCUMENTS_UPLOADED' | translate }}
            </p>
          }
          @if (formData.documentDetails?.notes) {
            <app-summary-section-field
              label="DOCUMENTS_UPLOAD.NOTES"
              [value]="formData.documentDetails?.notes">
            </app-summary-section-field>
          }
        </app-summary-section>
      }
      @switch (formData.selectedFlow) {
        @case (FLOW.REGISTRATION) {
          @if (formData.contact) {
            <app-registration-contact-data
              [contact]="formData.contact"></app-registration-contact-data>
          }
        }
        @case (FLOW.COMMISSIONING) {
          @if (formData.commissioningElectricityContact) {
            <app-commissioning-contact-data
              [contact]="
                formData.commissioningElectricityContact
              "></app-commissioning-contact-data>
          }
        }
      }
      <!-- print summary -->
      <app-summary-section
        heading="SUMMARY.PRINT_SUMMARY"
        icon="print"
        class="no-print print-section"
        onclick="window.print();">
      </app-summary-section>
      <div class="space"></div>
      <div class="required-checkboxes">
        <mat-checkbox
          color="primary"
          data-testid="accept-privacy-policy"
          [ngModel]="acceptPrivacyPolicy()"
          (ngModelChange)="acceptPrivacyPolicy.set($event)"
          appRequiredSuffix>
          {{ 'SUMMARY.ACCEPT_PRIVACY_POLICY.0' | translate }}
          <a
            class="bold"
            [href]="
              (whiteLabelConfig$ | async)?.whitelabelSettings?.legalSettings
                ?.dataProtectionURL
            "
            target="_blank"
            >{{ 'SUMMARY.ACCEPT_PRIVACY_POLICY.1' | translate }}</a
          >
          {{ 'SUMMARY.ACCEPT_PRIVACY_POLICY.2' | translate }}
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          data-testid="accept-communication"
          [ngModel]="acceptCommunication()"
          (ngModelChange)="acceptCommunication.set($event)"
          appRequiredSuffix
          >{{ 'SUMMARY.ACCEPT_COMMUNICATION' | translate }}
        </mat-checkbox>
      </div>
      <div class="space"></div>
      <!-- grid connection power change -->
      <ng-template #gridConnectionPowerChange>
        <app-summary-section
          heading="POWER_CHANGE_DETAILS.HEADING"
          [stepRoute]="routes.POWER_CHANGE_DETAILS">
          <div class="section-fields-container">
            <app-summary-section-field
              label="POWER_CHANGE_DETAILS.ADDITIONAL_CONSUMERS"
              [value]="
                (formData.powerChangeDetails?.additionalConsumers
                  ? 'COMMON.YES'
                  : 'COMMON.NO'
                ) | translate
              ">
            </app-summary-section-field>
            <app-summary-section-field
              label="POWER_CHANGE_DETAILS.NOTES_TITLE"
              [value]="formData.powerChangeDetails?.notes">
            </app-summary-section-field>
          </div>
          <p class="section-heading">
            {{ 'POWER_CHANGE_DETAILS.POWER_DEMAND' | translate }}
          </p>
          <div class="section-fields-container">
            <app-summary-section-field
              label="POWER_CHANGE_DETAILS.CURRENT_POWER_DEMAND"
              [value]="formData.powerChangeDetails?.currentPowerDemand">
            </app-summary-section-field>
            <app-summary-section-field
              label="POWER_CHANGE_DETAILS.PLANNED_POWER_DEMAND"
              [value]="formData.powerChangeDetails?.plannedPowerDemand">
            </app-summary-section-field>
          </div>
        </app-summary-section>
      </ng-template>
    }
    <div class="submit-captcha-container">
      <app-ngx-turnstile
        [resetWidget]="turnstileCaptchaReset"
        [siteKey]="turnstileSiteKey"
        (resolved)="onTokenResolve($event)"
        theme="light" />
    </div>
  </div>
  <app-form-navigation
    slot="navigation"
    [previousConfig]="{ label: 'COMMON.BACK' }"
    [nextConfig]="{
      label: 'COMMON.SUBMIT',
      disabled: nextDisabled(),
    }"
    (previous)="back()"
    (next)="submitData()"
    class="no-print">
  </app-form-navigation>
</app-form-card>
