import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { NextButtonDisabledPipe } from '@app/shared/pipes/next-button-disabled.pipe';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RequiredSuffixDirective } from '@app/shared/directives/required-suffix.directive';
import { SharedModule } from '@app/modules/shared/shared.module';
import { TranslatePipe } from '@ngx-translate/core';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { filter, map, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FORM_OF_FEED_IN_SALE } from '@app/models/registration-form';
import { StorageCustomerFacility } from '@app/models/form-data.interface';
import { DateHelperService } from '@app/services/date-helper.service';
import { CustomDateAdapter } from '@app/services/custom-date-adapter.service';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-storage-customer-facility',
  standalone: true,
  imports: [
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatRadioButton,
    MatRadioGroup,
    NextButtonDisabledPipe,
    ReactiveFormsModule,
    RequiredSuffixDirective,
    SharedModule,
    TranslatePipe,
  ],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
  templateUrl: './storage-customer-facility.component.html',
})
export class StorageCustomerFacilityComponent implements OnInit {
  readonly #appStateService: AppStateService = inject(AppStateService);
  readonly #formBuilder: FormBuilder = inject(FormBuilder);
  readonly #routeService: RouteService = inject(RouteService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #dateHelperService = inject(DateHelperService);

  public form!: FormGroup;
  public allTouched = false;
  public readonly FORM_OF_FEED_IN_SALE = Object.values(FORM_OF_FEED_IN_SALE);
  public readonly errorMessageForInvalidDateFormat =
    this.#dateHelperService.getErrorMessageForInvalidDateFormat();
  public readonly dateForTomorrow = this.#dateHelperService.getFutureDate(1);

  public ngOnInit(): void {
    this.createForm();
    this.updateForm();
  }

  private createForm(): void {
    this.form = this.#formBuilder.group({
      controllable: [null, Validators.required],
      plannedCommissioningDate: [null],
      formOfFeedInSale: [null, Validators.required],
    });
  }

  private updateForm(): void {
    this.#appStateService
      .observeState()
      .pipe(
        map(state => state.formData.storageCustomerFacility),
        filter(Boolean),
        take(1),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe(storageDetails => this.form.patchValue(storageDetails));
  }

  public previous(): void {
    this.updateState();
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.form.valid) {
      this.updateState();
      this.#routeService.navigateToNextStep();
    } else {
      this.form.markAllAsTouched();
      this.allTouched = true;
    }
  }

  private updateState() {
    const storageCustomerFacility: StorageCustomerFacility = this.form.value;
    this.#appStateService.updateFormData({ storageCustomerFacility });
  }
}
