export enum ROUTES {
  MODULE_SELECTION = 'module-selection',
  MAIN_DOMAIN_PAGE = 'main',
  ADDRESS = 'address',
  RESPONSIBILITY_CHECK_FAIL = 'responsibility-check-fail',
  DOCUMENTS_UPLOAD = 'documents-upload',
  CONTACT = 'contact',
  ELECTRICITY_PRODUCT_SELECTION = 'electricity-product-selection',
  PV_FACILITY_INFORMATION = 'pv-facility-information',
  STORAGE_INFORMATION = 'storage-information',
  STORAGE_CUSTOMER_FACILITY = 'storage-customer-facility',
  STORAGE_CONTROLLABLE_SYSTEM = 'storage-controllable-system',
  STORAGE_DETAILS = 'storage-details',
  CHARGING_DEVICE_OPTIONS = 'charging-device-options',
  CHARGING_DEVICE_MORE_OPTIONS = 'charging-device-more-options',
  CHARGING_DEVICE_PROPERTY_TYPE = 'charging-device-property-type',
  BALCONY_PV = 'balcony-pv',
  BALCONY_PV_EXIT = 'balcony-pv-exit',
  PV_TYPE = 'pv-type',
  CHANGE_PV_DETAILS = 'change-pv-details',
  GRID_CONNECTION_TYPE = 'grid-connection-type',
  GRID_CONNECTION_PROPERTY_TYPE = 'grid-connection-property-type',
  GRID_CONNECTION_CONSUMER = 'grid-connection-consumer',
  GRID_CONNECTION_CHANGE = 'grid-connection-change',
  POWER_CHANGE_DETAILS = 'power-change-details',
  HEAT_PUMP = 'heat-pump',
  OTHER = 'other',
  SUMMARY = 'summary',
  SUCCESS = 'success',
  ERROR = 'error',
  FLOW_SELECTION = 'flow-selection',
  GAS_OPTIONS = 'gas-options',
  GAS_PROPERTY_TYPE = 'gas-property-type',
  GAS_POWER_REQUIREMENT = 'gas-power-requirement',
  WATER_OPTIONS = 'water-options',
  SEWAGE_OPTIONS = 'sewage-options',
  SEWAGE_TYPE = 'sewage-type',
  WATER_AREA = 'water-area',
  WATER_PROPERTY_TYPE = 'water-property-type',
  WATER_REQUIREMENTS = 'water-requirements',
  CONSTRUCTION_WATER_REQUIREMENTS = 'construction-water-requirements',
  HEAT_OPTIONS = 'heat-options',
  HEAT_PROPERTY_TYPE = 'heat-property-type',
  HEAT_REQUIREMENTS = 'heat-requirements',
  COMMISSIONING_ELECTRICITY_OPTIONS = 'commissioning-electricity-options',
  COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_TYPE = 'commissioning-main-power-supply-type',
  COMMISSIONING_ELECTRICITY_MAIN_POWER_SUPPLY_POWER_INCREASE_DETAILS = 'commissioning-main-power-supply-power-increase-details',
  COMMISSIONING_ELECTRICITY_PROPERTY_TYPE = 'commissioning-electricity-property-type',
  COMMISSIONING_ELECTRICITY_INFORMATION = 'commissioning-electricity-information',
  COMMISSIONING_ELECTRICITY_CONTACT = 'commissioning-electricity-contact',
  COMMISSIONING_ELECTRICITY_SYSTEMS = 'commissioning-electricity-systems',
  COMMISSIONING_ELECTRICITY_SIMPLE_MEASUREMENT_DETAILS = 'commissioning-electricity-simple-measurement-details',
  COMMISSIONING_ELECTRICITY_MEASUREMENT_DETAILS = 'commissioning-electricity-measurement-details',
  CONSTRUCTION_ELECTRICITY = 'construction-electricity',
}
